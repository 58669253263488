











import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, PropSync } from 'vue-property-decorator'
import PaginationInterface from '../../../typings/PaginationInterface'

@Component
export default class GenericTablePagination extends Vue {
  @PropSync('pagination', { type: Object }) pag!: PaginationInterface

  rowsPerPageOptions: Array<number> = [10, 25, 50]
  rowsPerPage = 10

  get begin () {
    if (this.pag.pagenumber && this.pag.maxResults) {
      /* if (this.pag.pagecount === this.pag.pagenumber) {
        if (this.pag.resultfull && this.pag.results) {
          return this.pag.results - this.pag.rowcount + 1
        } else {
          if (this.pag.pagenumber) {
            return 1 + (this.pag.rowcount * (this.pag.pagenumber - 1))
          }
        }
      } else {
        if (this.pag.pagenumber) {
          return 1 + (this.pag.rowcount * (this.pag.pagenumber - 1))
        }
      } */
      return (1 + ((this.pag.pagenumber - 1) * this.pag.maxResults))
    } else {
      return 0
    }
  }

  get end () {
    if (this.pag.maxResults && this.pag.pagenumber && this.pag.total) {
      if (this.pag.total > (this.pag.pagesize * this.pag.pagenumber)) {
        return this.pag.pagesize * this.pag.pagenumber
      } else {
        return this.pag.total
      }
    } else {
      return 0
    }
  }

  get max () {
    if (this.pag.pagecount && this.pag.startAt !== undefined) {
      if ((this.pag.pagecount + this.pag.startAt) === this.pag.total) {
        return this.pag.total
      } else {
        return `${this.pag.total}+`
      }
    } else {
      return 0
    }
  }

  get disablePrevious () {
    if (this.pag.pagenumber) {
      if (this.pag.pagenumber > 1) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  @Watch('disablePrevious', { immediate: true })
  @Emit('updated:disablePrevious')
  disablePreviousEmit () {
    return this.disablePrevious
  }

  @Watch('disableNext', { immediate: true })
  @Emit('updated:disableNext')
  disableNextEmit () {
    return this.disableNext
  }

  get disableNext () {
    if (this.pag.maxResults && this.pag.pagenumber && this.pag.total) {
      if (this.pag.isLastPage !== undefined) {
        return this.pag.isLastPage
      } else {
        if (this.pag.total <= (this.pag.pagesize * this.pag.pagenumber)) {
          return true
        }
      }
    }
    return false
  }

  nextPage () {
    let page = 1
    if (this.pag.pagecount && this.pag.pagenumber) {
      if (this.pag.pagecount < this.pag.pagenumber) {
        page = this.pag.pagecount
      } else {
        if (this.pag.pagenumber) {
          page = this.pag.pagenumber + 1
        }
      }
    } else {
      if (this.pag.pagenumber) {
        page = this.pag.pagenumber + 1
      }
    }
    if (this.pag.pagenumber) {
      page = this.pag.pagenumber + 1
    }
    this.pag.pagenumber = page
  }

  previousPage () {
    let page = 1
    if (this.pag.pagecount && this.pag.pagenumber) {
      if (this.pag.pagecount < this.pag.pagenumber) {
        page = this.pag.pagecount
      } else {
        if (this.pag.pagenumber) {
          page = this.pag.pagenumber - 1
        }
      }
    } else {
      if (this.pag.pagenumber) {
        page = this.pag.pagenumber - 1
      }
    }
    this.pag = {
      ...this.pag,
      pagenumber: page
    }
  }

  updateRowsPerPage (val: any) {
    this.pag = {
      ...this.pag,
      pagesize: val
    }
  }
}
